import Vue from 'vue'
import Vuex from 'vuex'
import common from './modules/common'

Vue.use(Vuex)

const store = new Vuex.Store({
    state:{
        userAcc: '',
        userId: '',
        externalId: '',
        countMsg: {
            read: 0,
            unRead: 0
        },
        headerTips: `如有需求，可到C端浏览完整的商品池，并联系禹佳采购同事上架到B端，达不到集采起订量则同步C端价格，量大价更优哦~  Ps：活动请提前计划采购，如遇周末或节假日，发货时效无法保证！`
    },
    mutations: {
        setAcc(state, acc){
            state.userAcc = acc
        },
        setId(state, {userId, externalId}){
            state.userId = userId
            state.externalId = externalId
        },
        setMsg(state, data){
            state.countMsg = data
        }
    },
    modules: {
        common
    },
})


export default store