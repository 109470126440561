// import Vue from 'vue'
import router from '@/router'
// import store from '@/store'




/**
 * 清除登录信息
 */
export function clearLoginInfo () {
    localStorage.setItem('token','')
    localStorage.setItem('identity','')
    /*Vue.cookie.delete('token')*/
    // router.options.isAddDynamicMenuRoutes = false
    router.push({ name: 'Login' })
}