export default {
    namespaced:true,
    state:{
        GoodsList:[],
        MultipleSelection:[],
        GoodsInfor:[]
    },
    mutations:{
        updategoodsList (state, name) {
            state.GoodsList = name
        },
        upmultipleSelection(state, name) {
            state.MultipleSelection = name
        },
        upgoodsInfor(state, name) {
            state.GoodsInfor = name
        },
    }
}