<template>
  <div>
      <!-- 验证码弹窗 -->
        <el-popover placement="top" width="370" popper-class="slidingPictures" v-model="visible">
            <!-- 拼图验证码 -->
            <div v-show="visible" class="testCode">
            <p class="closeBtn"><i class="el-icon-close" @click="visible=!visible"></i></p>
            <slide-verify 
                :l="42"
                :r="20"
                :w="362"
                :h="140"
                slider-text="滑动验证"
                @success="onSuccess"
                @fail="onFail"
                @refresh="onRefresh"
                :style="{width:'362px'}"
                class="slide-box"
                ref="slideBlock"
                v-show="visible"
            ></slide-verify>
            </div>
        </el-popover>
  </div>
</template>

<script>
export default {
 name: "login",
 data() {
 return {
  tips: "拖动左边滑块完成上方拼图",
  
  visible: false,
  
 };
 },
 methods: {
     sonFun(param){
            this.visible=param
        },
    onSuccess (){
        // this.getLogin();//登录
        this.$router.replace({ name: 'Index' })
        },
        // 拼图失败
        onFail (){
        alert('拼图失败');
        },
        // 拼图刷新
        onRefresh (){
        alert('拼图刷新');
        },
        // 点击登录校验-拼图出现
    submitForm (formName) {
      // 表单校验
      this.$refs[formName].validate((valid) => {
        // 验证通过
        if (valid) {
          // 拼图出现
          this.flag = true
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  
 }
};
</script>

<style lang="scss">
.slidingPictures {
    height: 286px;
    top: 98px;
    left: 41px;
   
     
  }
</style>