import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/login.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'Login',//登录
    component: Login,
  },
  {
    path: '/findPassword',//找回密码
    name: 'FindPassword',
    component: () => import('@/views/findPassword.vue')
  },
  {
    path: '/index',//首页
    name: 'Index',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/index.vue')
  },
  {
    path: '/goodsDetail',//商品详情
    name: 'goodsDetail',
    component: () => import('@/views/goodsDetail.vue')
  },
  {
    path: '/shoppingCart',//购物车
    name: 'ShoppingCart',
    component: () => import('@/views/shoppingCart.vue')
  },
  {
    path: '/searchGoods',//搜索页
    name: 'SearchGoods',
    component: () => import('@/views/searchGoods.vue')
  },
  {
    path: '/goodsClose',//结算页
    name: 'GoodsClose',
    component: () => import('@/views/goodsClose.vue')
  },
  {
    path: '/workbench/accountSettings',//工作台>账号设置
    name: 'AccountSettings',
    component: () => import('@/views/workbench/accountSettings.vue')
  },
  {
    path: '/workbench/changePassword',//工作台>账号设置>密码修改 
    name: 'ChangePassword',
    component: () => import('@/views/workbench/changePassword.vue')
  },
  {
    path: '/workbench/bindEmail',//工作台>账号设置>绑定邮箱 
    name: 'BindEmail',
    component: () => import('@/views/workbench/bindEmail.vue')
  },
  {
    path: '/workbench/bindCellphone',//工作台>账号设置>绑定手机号 
    name: 'BindCellphone',
    component: () => import('@/views/workbench/bindCellphone.vue')
  },
  {
    path: '/workbench/shippAddress',//工作台>收货地址 
    name: 'ShippAddress',
    component: () => import('@/views/workbench/shippAddress.vue')
  },
  {
    path: '/workbench/newlyAddress',//工作台>收货地址>新增地址 
    name: 'NewlyAddress',
    component: () => import('@/views/workbench/newlyAddress.vue')
  },
  {
    path: '/workbench/editAddress',//工作台>收货地址>编辑地址 
    name: 'EditAddress',
    component: () => import('@/views/workbench/editAddress.vue')
  },
  {
    path: '/workbench/myOrder',//工作台>我的订单 
    name: 'MyOrder',
    component: () => import('@/views/workbench/myOrder.vue')
  },
  {
    path: '/workbench/myBill',//工作台>我的账单
    name: 'MyBill',
    component: () => import('@/views/workbench/myBill.vue')
  },
  {
    path: '/workbench/billDetails',//工作台>我的账单>账单详情
    name: 'BillDetails',
    component: () => import('@/views/workbench/billDetails.vue')
  },
  {
    path: '/workbench/myTopUp',//工作台>我的预充值
    name: 'MyTopUp',
    component: () => import('@/views/workbench/myTopUp.vue')
  },
  {
    path: '/workbench/company/index',//工作台>公司账号管理
    name: 'MyTopUp',
    component: () => import('@/views/workbench/company/index.vue')
  },
  {
    path: '/workbench/orderDetails',//工作台>我的订单>订单详情 
    name: 'OrderDetails',
    component: () => import('@/views/workbench/orderDetails.vue')
  },
  {
    path: '/workbench/invoiceCenter',//工作台>发票中心 
    name: 'InvoiceCenter',
    component: () => import('@/views/workbench/invoiceCenter.vue')
  },
  {
    path: '/workbench/invoiceDetails',//工作台>发票中心>发票详情
    name: 'InvoiceDetails',
    component: () => import('@/views/workbench/invoiceDetails.vue')
  },
  {
    path: '/workbench/applyPaper',//工作台>发票中心>申请纸质发票
    name: 'ApplyPaper',
    component: () => import('@/views/workbench/applyPaper.vue')
  },
  {
    path: '/workbench/addedQualification',//工作台>增票资质
    name: 'AddedQualification',
    component: () => import('@/views/workbench/addedQualification.vue')
  },
  {
    path: '/workbench/addTicket',//工作台>增票资质>添加增票资质
    name: 'AddTicket',
    component: () => import('@/views/workbench/addTicket.vue')
  },
  {
    path: '/workbench/salesRecord',//工作台>售后记录
    name: 'SalesRecord',
    component: () => import('@/views/workbench/salesRecord.vue')
  },
  {
    path: '/workbench/procurementAccount',//工作台>采购账号售后记录
    name: 'ProcurementAccount',
    component: () => import('@/views/workbench/procurementAccount.vue')
  },
  {
    path: '/workbench/changingRefund',//工作台>退换货
    name: 'ChangingRefund',
    component: () => import('@/views/workbench/changingRefund.vue')
  },
  {
    path: '/workbench/applySales',//工作台>退换货>申请售后
    name: 'ApplySales',
    component: () => import('@/views/workbench/applySales.vue')
  },
  {
    path: '/workbench/returnGoods',//工作台>退换货>详情
    name: 'ReturnGoods',
    component: () => import('@/views/workbench/returnGoods.vue')
  },
  {
    path: '/workbench/firmSelfhelp',//工作台>企业自助认款
    name: 'FirmSelfhelp',
    component: () => import('@/views/workbench/firmSelfhelp.vue')
  },
  {
    path: '/workbench/personalwork',//工作台>个人中心
    name: 'Personalwork',
    component: () => import('@/views/workbench/personalwork.vue')
  },
  {
    path: '/workbench/bulkOrder',//工作台>批量下单
    name: 'BulkOrder',
    component: () => import('@/views/workbench/bulkOrder.vue')
  },
  {
    path: '/workbench/setGoodsList',//工作台>商品设置>商品列表
    name: 'SetGoodsList',
    component: () => import('@/views/workbench/setGoodsList.vue')
  },
  {
    path: '/workbench/news',//消息
    name: 'news',
    component: () => import('@/views/workbench/news.vue')
  },
  {
    path: '/workbench/newsDetailed',//消息>消息详情
    name: 'newsDetailed',
    component: () => import('@/views/workbench/newsDetailed.vue')
  }
]

const router = new VueRouter({
  mode:'history',
  routes
})

export default router
