import request from '@/utils/request'
import qs from 'qs'
// import request from '@/utils/httpRequest'
/**
 * 验证码
 * @param params
 */
export function getCode(params) {
    return request({
        url: 'cpauth/oauth/smscode',
        method: 'get',
        params
    })
}
/**
 * 登录
 * @param data
 */
export function Login(data) {
    data = qs.stringify(data)
    return request({
        url: 'cpauth/login/mobile',
        method: 'post',
        headers: {
            "Authorization": "Basic " + 'emhnLWNwLWNsaWVudDo3YjE4MmNiM2ZhYmU0YTFiYjE0MTU4NDA0OTFlYWM4MQ=='
        },
        data
    })
}
/**
 * 首页分类
 */
export function Classify() {
    return request({
        url: 'goods/goodsType/queryGoodsType',
        method: 'get'
    })
}
/**
 * 首页展示商品 
 * @param data
 */
export function goodsList(data) {
    return request({
        url: 'goods/good/es/queryGoodsByType',
        method: 'post',
        header: {
            'content-type': 'application/json'
        },
        data
    })
}

/**
 * 首页查询
 * @param data
 */
export function Inquire(data) {
    // debugger
    return request({
        url: 'goods/good/es/getGoodsList',
        method: 'post',
        header: {
            'content-type': 'application/json'
        },
        data
    })
}


/**
 * 商品详情
 * @param id
 */
export function goodsDetails(params) {
    return request({
        url: 'goods/good/queryGoodsById',
        method: 'get',
        params
    })
}

/**
 * 加入购物车
 * @param data
 */
export function joinShopCart(data) {
    return request({
        url: '/goods/good/shopping-cart/addToCart',
        method: 'put',
        // header: {
        //     'Content-Type':"application/x-www-form-urlencoded"
        // },
        data
    })
}

/**
 * 商品类型
 * @param data
 */
export function classList(data) {
    return request({
        url: 'goods/good/es/queryGoodsByType',
        method: 'post',
        header: {
            'content-type': 'application/json'
        },
        data
    })
}
/**
 * 地址
 * @param params
 */
export function goodsAddress(params) {
    return request({
        url: 'cpuser/user/address/getAddress',
        method: 'get',
        params
    })
}

/**
 * 地址
 * @param data
 */
export function getidentity(data) {
    return request({
        url: 'cpuser/user/address/getAddress',
        method: 'get',
        data
    })
}

/**
 * 用户信息
 * @param 
 */
export function userInfor() {
    return request({
        url: 'cpuser/user/userRoleMapper/queryUserDetail',
        method: 'get',
    })
}

/**
 * 查询购物车
 * @param data
 */
export function queryListAndPage(data) {
    return request({
        url: 'goods/good/shopping-cart/queryListAndPage',
        method: 'post',
        data
    })
}

/**
 * 购物车商品数量增减
 * @param data
 */
export function shoppingCartNumsChange(data) {
    return request({
        url: 'goods/good/shopping-cart/shoppingCartNumsChange',
        method: 'post',
        data
    })
}

/**
 * 导出购物车数据
 * @param data
 */
export function exportShoppingCartDatas(data) {
    return request({
        url: 'goods/good/shopping-cart/exportShoppingCartDatas',
        method: 'post',
        responseType:'blob',
        data
    })
}

/**
 * 删除购物车商品
 * @param data
 */
export function deleteShoppingCart(data) {
    return request({
        url: 'goods/good/shopping-cart/deleteShoppingCart',
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        data
    })
}


/**
 * 获取购物车数量
 * @param 
 */
 export function carNumber() {
    return request({
        url: 'goods/good/shopping-cart/shoppingCartNumCount',
        method: 'get',
    })
}

/**
 * 导出购物车数据
 * @param data
 */
 export function exportBulkorderDatas(data) {
    return request({
        url: 'goods/good/shopping-cart/exportShoppingCartBatchDatas',
        method: 'post',
        responseType:'blob',
        data
    })
}

/**
 * 批量下单数据导出(全)
 * @param data
 */
 export function exportShoppingCartBatchDataAll(data) {
    return request({
        url: 'goods/good/shopping-cart/exportShoppingCartBatchDataAll',
        method: 'post',
        responseType:'blob',
        data
    })
}

/**
 * 
京东商品异步查询库存
 * @param data
 */
 export function queryAndAddress(data) {
    return request({
        url: 'goods/good/shopping-cart/queryJDStockBySourceIdsAndAddress',
        method: 'post',
        data
    })
}

/**
 * 
震坤行商品异步查询库存
 * @param data
 */
export function querySourceIdsAndAddress(data) {
    return request({
        url: 'goods/good/shopping-cart/queryZKHStockBySourceIdsAndAddress',
        method: 'post',
        data
    })
}

/**
 * 
商品配置 获取
 * @param data
 */
export function sysOptionalConfigQuery(id) {
    return request({
        url: `/admin/admin/sys-optional-config/query/${id}`,
        method: 'get'
    })
}

// 外部企业公司账户管理

/**
 * 
外部企业员工账户列表
 * @param data
 */
export function externalQueryPage(data) {
    return request({
        url: 'admin/admin/externalBulkPurchaseEmployee/queryPage',
        method: 'post',
        data
    })
}

/**
 * 
外部企业组织架构树数据查询
 * @param data
 */
export function externalGroupData(data) {
    return request({
        url: 'admin/admin/externalBulkPurchaseCompanyInfo/queryGroupData',
        method: 'post',
        data
    })
}

/**
 * 
新增外部组织员工信息
 * @param data
 */
export function externAladdUser(data) {
    return request({
        url: 'admin/admin/externalBulkPurchaseEmployee/addUser',
        method: 'post',
        data
    })
}

/**
 * 
冻结员工账户信息
 * @param data
 */
export function frozenUser(userId) {
    return request({
        url: `admin/admin/externalBulkPurchaseEmployee/frozenUser/${userId}`,
        method: 'GET'
    })
}

/**
 * 
解冻员工账户信息
 * @param data
 */
export function unfrozenUser(userId) {
    return request({
        url: `admin/admin/externalBulkPurchaseEmployee/unfrozenUser/${userId}`,
        method: 'GET'
    })
}

/**
 * 
删除员工账户信息
 * @param data
 */
export function deleteUser(userId) {
    return request({
        url: `admin/admin/externalBulkPurchaseEmployee/deleteUser/${userId}`,
        method: 'GET'
    })
}

/**
 * 
外部企业员工账户列表(导出用)
 * @param data
 */
export function externExportExternalUser(data) {
    return request({
        url: 'admin/admin/externalBulkPurchaseEmployee/exportExternalUser',
        method: 'post',
        responseType: 'blob',
        data
    })
}
/**
 * 
修改外部组织员工信息
 * @param data
 */
export function externUpdateCompany(data) {
    return request({
        url: 'admin/admin/externalBulkPurchaseEmployee/updateCompany',
        method: 'post',
        data
    })
}

/**
 * 
根据ID获取外部企业员工信息
 * @param data
 */
export function externUserInfo(userId) {
    return request({
        url: `admin/admin/externalBulkPurchaseEmployee/userInfo/${userId}`,
        method: 'POST'
    })
}

/**
 * 
修改组织信息
 * @param data
 */
export function externCompanyInfoUpdateCompany(data) {
    return request({
        url: `admin/admin/externalBulkPurchaseCompanyInfo/updateCompany`,
        method: 'POST',
        data
    })
}

/**
 * 
新增外部组织信息
 * @param data
 */
export function addCompany(data) {
    return request({
        url: `admin/admin/externalBulkPurchaseCompanyInfo/addCompany`,
        method: 'POST',
        data
    })
}

/**
 * 
删除组织信息
 * @param data
 */
export function deleteCompany(companyId) {
    return request({
        url: `admin/admin/externalBulkPurchaseCompanyInfo/deleteCompany/${companyId}`,
        method: 'GET'
    })
}

/**
 * 
删除项目
 * @param data
 */
export function deleteProject(projectId) {
    return request({
        url: `admin/admin/externalBulkPurchaseCompanyInfo/deleteProject/${projectId}`,
        method: 'GET'
    })
}

/**
 * 
 * 外部企业商品列表
 * @param params
 */
export function pcQueryPage(params) {
    return request({
        url: `/admin/admin/external-goods/pcQueryPage`,
        method: 'get',
        params
    })
}

/**
 * 
 * 外部企业商品信息变更
 * @param data
 */
export function externalGoodsUpdate(data) {
    return request({
        url: `/admin/admin/external-goods/externalGoodsUpdate`,
        method: 'put',
        data
    })
}

/**
 * 
 * 导出外部企业商品
 * @param params
 */
export function pcExportExternalGoods(params) {
    return request({
        url: `/admin/admin/external-goods/pcExportExternalGoods`,
        method: 'get',
        responseType: 'blob',
        params
    })
}
/**
 * 
 * 批量设置商品别名与编码
 * @param data
 */
export function externalGoodsBatchUpdate(data) {
    return request({
        url: `/admin/admin/external-goods/externalGoodsBatchUpdate`,
        method: 'put',
        data
    })
}

/**
 * 
消息数量获取
 * @param data
 */
export function countMsg() {
    return request({
        url: `/admin/admin/msg-push-record/countMsg`,
        method: 'POST'
    })
}

/**
 * 
消息列表
 * @param data
 */
export function countQueryPage(data) {
    return request({
        url: `/admin/admin/msg-push-record/queryPage`,
        method: 'POST',
        data
    })
}

/**
 * 
标记消息为已读
 * @param params
 */
export function markRead(params) {
    return request({
        url: `/admin/admin/msg-user-receive-info/mark`,
        method: 'get',
        params
    })
}

/**
 * 
 * 删除消息
 * @param params
 */
export function deleteMsg(id) {
    return request({
        url: `/admin/admin/msg-push-record/deleteMsg/${id}`,
        method: 'get'
    })
}

/**
 * 
 * 消息详情
 * @param params
 */
export function msgDetail(id) {
    return request({
        url: `/admin/admin/msg-push-record/msgDetail/${id}`,
        method: 'get'
    })
}

/**
 * 
查询用户是否有【导出批量下单模板(全)】功能的权限
 * @param data
 */
export function checkAuthFlag(data) {
    return request({
        url: `goods/good/shopping-cart/checkAuthFlag`,
        method: 'POST',
        data
    })
}

/**
 * 
 * 是否需要审批员审批（pc商城专用）
 * 
 */
export function needAuditor() {
    return request({
        url: `admin/admin/external-msg/needAuditor`,
        method: 'GET'
    })
}

/**
 * 
 * 查看是否需要审批员状态（pc商城专用）
 * 
 */
export function needAuditorStatus() {
    return request({
        url: `admin/admin/external-msg/needAuditorStatus`,
        method: 'GET'
    })
}
