<template>
    <div class="footer">
        <!-- <el-row type="flex" class="footer_box">
            <el-col :span="3"></el-col>
            <el-col :span="18">
                <div class="flex-center">
                    <div class="flex-start footer_top_left">
                        <ul>
                            <li>购物指南</li>
                            <li>购物流程</li>
                            <li>会员介绍</li>
                            <li>生活旅行</li>
                            <li>常见问题</li>
                            <li>联系客服</li>
                        </ul>
                        <ul>
                            <li>常见问题</li>
                            <li>购物流程</li>
                            <li>会员介绍</li>
                            <li>生活旅行</li>
                            <li>常见问题</li>
                            <li>联系客服</li>
                        </ul>
                        <ul>
                            <li>售后服务</li>
                            <li>购物流程</li>
                            <li>会员介绍</li>
                            <li>生活旅行</li>
                            <li>常见问题</li>
                            <li>联系客服</li>
                        </ul>
                        <ul>
                            <li>服务协议</li>
                            <li>购物流程</li>
                            <li>会员介绍</li>
                            <li>生活旅行</li>
                            <li>常见问题</li>
                            <li>联系客服</li>
                        </ul>
                    </div>
                    <div class="footer_top_right">
                        <div class="footer_infor">
                            客服电话（7*24小时）：
                            <div>400-000-0000</div>
                        </div>
                        
                        <div class="footer_infor">
                            客服邮箱：
                            <div>123456789@qq.com</div>
                        </div>
                        <div class="consult">咨询在线客服</div>
                    </div>
                </div>
                <div class="footer_btom">
                    <div style="margin-top:10px;">
                        Copyright ©2012-2015 12/5000 Xiamen Zongheng Technology Co., Ltd All Rights Reserved
                    </div>
                    <div>
                        厦门纵横科技有限公司  闽ICP备12345678号
                    </div>
                </div>
            </el-col>
            <el-col :span="3"></el-col>
        </el-row> -->
        <div class="footer_old_index">
            <div style="margin-top:10px;">
                Copyright ©2012-2015 12/5000 Xiamen Zongheng Technology Co., Ltd All Rights Reserved
            </div>
            <div>
                厦门纵横科技有限公司  闽ICP备12345678号
            </div>
        </div>
    </div>
</template>

<script>
    export default {

    }
</script>

<style lang="scss">
    .footer_box{
        // background: #868E90;
        padding: 39px 53px 15px;
        color: #333;
    }
    .footer_old_index{
        border-top: 1px solid #333;
        margin-top: 60px;
        margin-bottom: 20px;
        text-align: center;
        font-size: 14px;
    }

    .footer_top_left{
        margin-top: 15px;
        width: 80%;
        margin-right: 100px;
    }
    .footer_top_left ul{
        width: 24%;
        text-align: center;
    }
    .footer_top_left ul li{
        font-size: 14px;
        margin-bottom: 10px;
        letter-spacing:2px;
    }
    .footer_top_left li:first-child{
        font-size:18px;
        margin-bottom: 35px;
    }
    .footer_top_right{
        width: 20%;
        background:#333;
        color: black;
        padding: 35px 55px;
        font-size:18px;
        margin-right: 30px;
    }
    .footer_infor{
        margin-bottom: 25px;
    }
    .footer_infor>div{
        margin-top:10px;
    }
    .consult{
        width: 107px;
        height: 33px;
        line-height: 33px;
        text-align: center;
        border: 1px solid black;
        background: #fff;
        font-size:13px;
        border-radius: 4px;
    }
    .footer_btom{
        border-top: 1px solid #333;
        margin-top: 20px;
        text-align: center;
        font-size: 14px;
    }
</style>