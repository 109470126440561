import axios from 'axios'
import router from '@/router'
import {
    clearLoginInfo
} from '@/utils'
// import qs from 'qs'
// import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
    baseURL: window.location.origin+'/api/', // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 10000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        
        if(config.url!="cpauth/login/mobile"){
            const token = localStorage.getItem('token');
            config.headers.Authorization = token;
        }
        if(config.url=="orders/order/batchOrder/batchSubmitOrder" || config.url=="orders/orderMsg/queryOrderStatus"){
            config.timeout=50000
        }
        if(config.url=="orders/orderMsg/exportOrder"){
            config.timeout=1000*10*60
        }
        // console.log(config.headers.Authorization)

        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)


// response interceptor
service.interceptors.response.use(

    response => {
        //   hideLoading()
        const res = response.data
        // console.log(res,888)
        if (response.data && response.data.code === 'A0230') { // A0230, token失效
            clearLoginInfo()
            localStorage.setItem('token', '')
            router.push({
                name: 'Login'
            })
            // return Promise.reject(new Error(res.message || 'Error'))
        }else if(response.data && response.data.code === 'A0220'){
            clearLoginInfo()
            localStorage.setItem('token', '')
            router.push({
                name: 'Login'
            })
        }else if(response.data && response.data.code === 'A0231'){
            clearLoginInfo()
            localStorage.setItem('token', '')
            router.push({
                name: 'Login'
            })
        } else {
            //blob类型为文件下载对象，不论是什么请求方式，直接返回文件流数据
            // console.log(response, 'response');
            if (response.config.responseType === 'blob' || response.config.responseType === 'arraybuffer') { //下载excel类型
                let blob = new Blob([res], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
                }); //application/vnd.openxmlformats-officedocument.spreadsheetml.sheet这里表示xlsx类型
                let downloadElement = document.createElement('a');
                let href = window.URL.createObjectURL(blob); //创建下载的链接
                downloadElement.href = href;
                //获取文件名
                let fileName = decodeURI(response.headers['content-type'].split(";")[1].split("=")[1]); //处理文件名乱码问题
                downloadElement.download = fileName; //下载后文件名
                document.body.appendChild(downloadElement);
                downloadElement.click(); //点击下载
                document.body.removeChild(downloadElement); //下载完成移除元素
                window.URL.revokeObjectURL(href); //释放掉blob对象
            }
            return res
        }
    },
    error => {
        //   hideLoading()
        console.log('err' + error) // for debug
        // Message({
        //   message: error.message,
        //   type: 'error',
        //   duration: 5 * 1000
        // })
        // return Promise.reject(error)
    }
)
export default service